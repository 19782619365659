<template>
  <div>
    <el-breadcrumb separator-class="el-icon-arrow-right">
      <el-breadcrumb-item :to="{ path: '/home' }">首页</el-breadcrumb-item>
      <el-breadcrumb-item>商品管理</el-breadcrumb-item>
      <el-breadcrumb-item>商品列表</el-breadcrumb-item>
    </el-breadcrumb>

    <el-card>

      <el-row :gutter="20">
        <el-col :span="8">
          <el-input placeholder="请输入内容" v-model="queryInfo.name" clearable>
          </el-input>
        </el-col>
        <el-col :span="2"><el-button @click="getGoodsList" icon="el-icon-search">搜索</el-button></el-col>
        <el-col :span="2">
          <el-button type="primary" @click="goAddpage">添加商品</el-button> <!-- 点击后跳转到路由/goods/add还是这个页面位置显示 -->
        </el-col>
      </el-row>

      <!-- table表格区域 -->
      <el-table :data="goodslist" border stripe >
        <el-table-column type="index" ></el-table-column>
        <el-table-column label="商品标题" prop="name" width="140px"></el-table-column>
        <el-table-column label="商品副标题" prop="subheading" width="140px"></el-table-column>
        <el-table-column label="封面图片" prop="coverUrl" >
          <template slot-scope="scope"> <!-- 作用域插槽 新版是v-slot="scope" scope.row代表这一行的所有数据 这是从上面userlist里拿出来的 -->
            <img style="height: 30px;" :src="scope.row.coverUrl" />
          </template>
        </el-table-column>
        <el-table-column label="原商品价格" prop="originalPrice" ></el-table-column>
        <el-table-column label="商品出售价格(元)" prop="price" ></el-table-column>
        <el-table-column label="商品销量" prop="salesVolume"></el-table-column>
        <el-table-column label="阅读量" prop="readingQuantity" ></el-table-column>
        <el-table-column label="购买积分" prop="score" ></el-table-column>
        <el-table-column label="创建人" prop="createBy" width="140px"></el-table-column>
        <el-table-column label="状态">
          <template slot-scope="scope"> <!-- 作用域插槽 新版是v-slot="scope" scope.row代表这一行的所有数据 这是从上面userlist里拿出来的 -->
            <el-switch v-model="scope.row.state" :active-value="1" :inactive-value="0"
              @change="amend(scope.row)"> <!-- 将开关的状态和数据里的mg_state绑定 -->
            </el-switch>
          </template>

        </el-table-column>
        <el-table-column label="创建时间" prop="createTime" width="140px"></el-table-column>
        <el-table-column label="操作" width="130px">
          <template slot-scope="scope">
            <el-button type="primary" icon="el-icon-edit" size="mini"  @click="ProductModification(scope.row.id)"></el-button>
            <el-button type="danger" icon="el-icon-delete" size="mini" @click="removeById(scope.row.id)"></el-button>
          </template>
        </el-table-column>
      </el-table>

      <!-- 分页区域 -->
      <el-pagination @size-change="handleSizeChange" @current-change="handleCurrentChange" :current-page="queryInfo.page" :page-sizes="[5, 10, 15, 20]" :page-size="queryInfo.pageSize" layout="total, sizes, prev, pager, next, jumper" :total="total" background>
      </el-pagination>

    </el-card>
  </div>
</template>

<script>
export default {
  data() {
    return {
      // 查询参数对象
      queryInfo: {
        query: '',
        page: 1,
        pageSize: 10,
        state:1,
        name: ''
      },
      // 商品列表
      goodslist: [],
      // 总数据条数
      total: 0,
    }
  },
  created() {
    this.getGoodsList()
  },
  methods: {
    // 跳转到添加商品页面
    ProductModification(id){
      this.$router.push({
        path:`/goods/add`,
        query:{
          id:id
        }
      })
    },
    // 根据分页获取对应的商品列表
    async getGoodsList() {
      const { data: res } = await this.$http.get('/Commodity/getCommodityList', {
        params: this.queryInfo
      })
      if (res.code != 1) {
        return this.$message.error('获取商品列表失败！')
      }
      this.$message.success('获取商品列表成功！')
      console.log(res.data)
      this.goodslist = res.data.records
      this.total = res.data.total
    },
     // 监听 switch 开关状态的改变 调用put请求把原数据更改 这样每次刷新页面时就能保存这个状态 相当于把这个状态存储起来了（原数据存储的方式）
     async amend(item) {
      const { data: res } = await this.$http.get('/Commodity/amend', {
        params: {
          id: item.id,
          state:item.state ==0?0:1
        }
      })
      if (res.code != 1) { /* 原数据修改失败 页面的状态重置回去（保持跟原数据一样 刷新也行） */
        return this.$message.error('更新商品状态失败！')
      }
      this.$message.success('更新商品状态成功！');
      this.getCooperationList()
    },
    // -------
    handleSizeChange(newSize) {
      this.queryInfo.pageSize = newSize
      this.getGoodsList()
    },
    handleCurrentChange(newPage) {
      this.queryInfo.page = newPage
      this.getGoodsList()
    },
    async removeById(id) {
      const confirmResult = await this.$confirm(
        '此操作将永久删除该商品, 是否继续?',
        '提示',
        {
          confirmButtonText: '确定',
          cancelButtonText: '取消',
          type: 'warning'
        }
      ).catch(err => err)

      if (confirmResult !== 'confirm') {
        return this.$message.info('已经取消删除！')
      }

      const { data: res } = await this.$http.get(`/Commodity/delete`,{
      params: {id:id}
      })

      if (res.code != 1) {
        return this.$message.error('删除失败！')
      }

      this.$message.success('删除成功！')
      this.getGoodsList()
    },
    goAddpage() {
      this.$router.push('/goods/add')
    }
  }
}
</script>

<style lang="less" scoped>
</style>
