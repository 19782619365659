import axios from "axios";
// import store from "@/store";

export default {
    ///图片上传
    async fileValueChange(file) {
        let Msgdata = {
            msg: "",
            success: false,
            data: null
        }
        await axios({
            method: "get",
            url: "/Basic/getCredential", //获取临时密钥
        }).then((res) => {
  
            const { status, data } = res;
            if (status == 200 ) {
                    var params = new FormData();
                    params.append("tmpSecretId", res.data.data.credentials.tmpSecretId);
                    params.append(
                        "tmpSecretKey",
                        res.data.data.credentials.tmpSecretKey
                    );
                    params.append(
                        "sessionToken",
                        res.data.data.credentials.sessionToken
                    );
                    params.append("type", 1);
                    params.append("countType", 1);
                    params.append("file", file.file);

                    Msgdata.data = params;
                    Msgdata.success = true;
            } else {
                Msgdata.msg = "临时密钥" + data.errorMsg
            }
        });
        return Msgdata
    },
    async imgupload(params) {
        let Msgdata = {
            msg: "",
            success: false,
            data: null
        }
        await axios.post("/Basic/uploadFile", params).then((tres) => {
             //console.log(tres)
            if (tres.data.code === 1) {
                    Msgdata.data = tres.data.data;
                    Msgdata.success = true
                    Msgdata.msg = "图片上传成功！"

            
            } else {
                Msgdata.msg = "图片上传请求失败！"
            }
        });
        return Msgdata;
    },


}