<template>
  <div>
    <el-breadcrumb separator-class="el-icon-arrow-right">
      <el-breadcrumb-item :to="{ path: '/home' }">首页</el-breadcrumb-item>
      <el-breadcrumb-item>商品管理</el-breadcrumb-item>
      <el-breadcrumb-item>添加商品</el-breadcrumb-item>
    </el-breadcrumb>
    <el-card>
      <el-alert title="添加商品信息" type="info" center show-icon :closable="false">
      </el-alert>
      <el-steps :space="200" :active="activeIndex - 0" finish-status="success" align-center>
        <!-- 减0是为了将下方的字符串转为数值 共用同一个数据项 -->
        <el-step title="基本信息"></el-step>
        <el-step title="商品图片"></el-step>
        <el-step title="完成"></el-step>
      </el-steps>
      <!-- tab栏区域 tabs里面不能放form 但form里面可以放tabs 所以要把form放外面 在每个tab-pane里面添加表单项 -->
      <el-form :model="addForm" :rules="addFormRules" ref="addFormRef" label-width="100px" label-position="top">
        <el-tabs v-model="activeIndex" :tab-position="'left'" :before-leave="beforeTabLeave" @tab-click="tabClicked">
          <el-tab-pane label="基本信息" name="0">
            <el-form-item label="商品封面图片">
                <div @click="setType(4)">
                  <el-upload action="#" list-type="picture-card" :show-file-list="false" :http-request="fileValueChange"
                    :on-success="handleAvatarSuccess" :before-upload="beforeAvatarUpload">
                    <img style="width: 100%; height: 100%" v-if="addForm.coverUrl" :src="addForm.coverUrl" title="点击修改或上传图片" />
                    <i v-else class="el-icon-plus avatar-uploader-icon"></i>
                  </el-upload>
                </div>
              </el-form-item>
            <el-form-item label="商品标题" prop="name">
              <el-input v-model="addForm.name"></el-input>
            </el-form-item>
            <el-form-item label="商品副标题" prop="subheading">
              <el-input v-model="addForm.subheading"></el-input>
            </el-form-item>
            <el-form-item label="商品购买积分" prop="score">
              <el-input v-model="addForm.score" type="number"></el-input>
            </el-form-item>
            <el-form-item label="商品标签" prop="label">
              <el-input v-model="addForm.label"></el-input>
            </el-form-item>
            <el-form-item label="商品价格" prop="price">
              <el-input v-model="addForm.price" type="number"></el-input>
            </el-form-item>
            <el-form-item label="商品原价格" prop="originalPrice">
              <el-input v-model="addForm.originalPrice" type="number"></el-input>
            </el-form-item>
            <div style="border: #e5e5e5 solid 2px;" v-for="(item, index) in addForm.productColorList" :key="index">
              <el-form-item label="商品颜色名称">
                <el-input v-model="item.name"></el-input>
              </el-form-item>
              <el-form-item label="商品数量">
                <el-input v-model="item.quantity" type="number"></el-input>
              </el-form-item>
              <el-form-item label="商品规格图片">
                <div @click="setType(1)">
                  <el-upload action="#" list-type="picture-card" :show-file-list="false" :http-request="fileValueChange"
                    :on-success="handleAvatarSuccess" :before-upload="beforeAvatarUpload">
                    <img style="width: 100%; height: 100%" v-if="item.url" :src="item.url" title="点击修改或上传图片" />
                    <i v-else class="el-icon-plus avatar-uploader-icon"></i>
                  </el-upload>
                </div>
              </el-form-item>
              <el-button type="primary" class="btnAdd" @click="DeleteSpecification(index)">删除规格</el-button>
            </div>
            <el-button type="primary" class="btnAdd" @click="AddSpecifications">添加规格</el-button>
            <!-- <el-form-item label="商品分类" prop="goods_cat">
              <el-cascader expand-trigger="hover" :options="catelist" :props="cateProps" v-model="addForm.goods_cat" @change="handleChange"> 
              </el-cascader>
            </el-form-item> -->
          </el-tab-pane>
          <el-tab-pane label="商品图片" name="3">
            <el-form-item label="商品轮播图">
              <!-- <div v-for="(item,index) in addForm.slideshow" :key="index" >
                    <img style="width: 200px; height: 200px" v-if="item" :src="item" title="点击修改或上传图片" />
                    <i  class="el-icon-plus avatar-uploader-icon"></i>
            </div>    -->
              <div @click="setType(2)">
                <div style="display: flex;">
                  <div v-for="(item, index) in addForm.slideshow" :key="index">
                    <img style="width: 100px; " v-if="item" :src="item" title="点击修改或上传图片" />
                  </div>
                </div>
                <el-upload action="#" list-type="picture-card" :show-file-list="false" :http-request="fileValueChange"
                  :on-success="handleAvatarSuccess" :before-upload="beforeAvatarUpload" :limit="9" :multiple="true">
                </el-upload>
              </div>
            </el-form-item>
            <el-form-item label="商品宝贝图片详情">
              <div @click="setType(3)">
                <div style="display: flex;">
                  <div v-for="(item, index) in addForm.details" :key="index">
                    <img style="width: 100px; " v-if="item" :src="item" title="点击修改或上传图片" />
                  </div>
                </div>
                <el-upload action="#" list-type="picture-card" :show-file-list="false" :http-request="fileValueChange"
                  :on-success="handleAvatarSuccess" :before-upload="beforeAvatarUpload" :limit="9" :multiple="true">
                </el-upload>
              </div>
            </el-form-item>
            <el-form-item label="商品详情介绍">
              <el-input v-model="addForm.describe"></el-input>
            </el-form-item>
            <el-button type="primary" class="btnAdd" @click="add">添加商品</el-button>
          </el-tab-pane>
        </el-tabs>
      </el-form>
    </el-card>

    <!-- 图片预览 -->
    <el-dialog title="图片预览" :visible.sync="previewVisible" width="50%">
      <img :src="previewPath" alt="" class="previewImg"> <!-- previewPath存储着图片的url -->
    </el-dialog>

  </div>
</template>

<script>
import _ from 'lodash' /* 导入lodash再调用cloneDeep方法 推荐用_接收 类似jquery用$接收 */
import upload from "../../utls/upload.js"
let upType = 2;
export default {
  data() {
    return {
      id:null,
      activeIndex: '0',
      // 表单数据对象 传给服务器发送请求 （看文档的参数要求）
      addForm: {
        slideshow: [],
        describe: '',
        price: '',
        classifyId: 1,
        name: '',
        score:'',
        subheading:'',
        details: [],
        quantity:0,
        originalPrice: '',
        // 商品所属的分类数组 存储着级联选择器的选择项 而且要将数组转为以逗号分隔的字符串
        // goods_cat: [11],
        // 图片的数组
        productColorList: [],
        coverUrl:''
        // 商品的详情描述
      },
      addFormRules: {
        name: [
          { required: true, message: '请输入商品标题', trigger: 'blur' }
        ],
        subheading: [
          { required: true, message: '请输入商品副标题', trigger: 'blur' }
        ],
        coverUrl: [
          { required: true, message: '请上传封面图片', trigger: 'blur' }
        ],
        price: [
          { required: true, message: '请输入商品价格', trigger: 'blur' }
        ],
        score: [
          { required: true, message: '请输入商品购买积分', trigger: 'blur' }
        ],
        originalPrice: [
          { required: true, message: '请输入商品原价格', trigger: 'blur' }
        ],
        quantity: [
          { required: true, message: '请输入商品数量', trigger: 'blur' }
        ],
        // goods_cat: [
        //   { required: true, message: '请选择商品分类', trigger: 'blur' }
        // ]
      },
      // 商品分类列表
      // catelist: [],
      // cateProps: { /* 级联选择器的配置项 */
      //   label: 'cat_name',
      //   value: 'cat_id',
      //   children: 'children'
      // },
      // 动态参数列表数据
      manyTableData: [],
      // 静态属性列表数据
      onlyTableData: [],
      // 上传图片的URL地址

      // 图片上传组件的headers请求头对象
      headerObj: {
        Authorization: window.sessionStorage.getItem('token')
      },
      previewPath: '',
      previewVisible: false
    }
  },
  created() {
    //获取上一页的id
    this.id = this.$route.query.id
if(this.id){
  this.getCommodityDetails();
}
    console.log(this.$route.query);
    

  },
  methods: {
    //获取接口数据渲染到页面
    async getCommodityDetails() {
      const { data: res } = await this.$http.get('/Commodity/getCommodityDetails', {
        params: {
          id:this.id
        }
      })
      let obj = res.data;
      //json字符串转对象
// console.log(JSON.parse("[\"http://img.iyoujian.net/yjb/sift/img/a2cc0b4d368049b1ac670970b5707916.jpeg\", \"http://img.iyoujian.net/yjb/sift/img/a2cc0b4d368049b1ac670970b5707916.jpeg\"]"));
      obj.slideshow =  JSON.parse( obj.slideshow);
      obj.details =  JSON.parse( obj.details);
      console.log(obj);
      this.addForm =obj;

    },

    DeleteSpecification(index) {
      this.addForm.productColorList.splice(index, 1);
    },
    setType(type) {
      // type==1时表示上传的是规格参数的图片
      upType = type;
    },
    AddSpecifications() {
      let obj = {
        url: "",
        salesVolume: "",
        name: "",
        salesVolume: ''
      }
      this.addForm.productColorList.push(obj);
    },
    handleAvatarSuccess(res, file) {
      this.imageUrl = URL.createObjectURL(file.raw);
    },
    beforeAvatarUpload(file) {
      const isJPG = file.type === "image/jpeg"; // 格式
      const isType = file.type === "image/png";
      const isLt2M = file.size / 1024 / 1024 < 2;
      if (!isJPG && !isType) {
        this.$message.error("上传头像图片只能是 JPG 或 PNG 格式!");
        return false;
      }
      if (!isLt2M) {
        this.$message.error("上传头像图片大小不能超过 2MB!");
        return false;
      }
      return isJPG || (isType && isLt2M);
    },
    fileValueChange(val) {

          var params = new FormData();
          params.append("file", val.file);
          upload.imgupload(params).then((data) => {
            if (data.success) {
              if (upType == 1) {
                // type==1时表示上传的是规格参数的图片
                let list = this.addForm.productColorList;

                list[list.length - 1].url = data.data;

                this.addForm.productColorList = list;
              } else if (upType == 2) {
                this.addForm.slideshow.push(data.data);

              } else if (upType == 3) {
                this.addForm.details.push(data.data);
              }else if(upType == 4){
                this.addForm.coverUrl = data.data;
              }
              this.$message.success(data.msg);
              return;
            }
            this.$message.error(data.msg);
          });
     
    },
    handleExceed(files, fileList) {
      this.$message.warning(
        `当前限制选择 3 个文件，本次选择了 ${files.length} 个文件，共选择了 ${files.length + fileList.length
        } 个文件`
      );
    },
    // -----------------------
    // 获取所有商品分类数据
    // async getCateList() {
    //   const { data: res } = await this.$http.get('categories')

    //   if (res.code != 1) {
    //     return this.$message.error('获取商品分类数据失败！')
    //   }

    //   this.catelist = res.data
    //   console.log(this.catelist)
    // },
    // 级联选择器选中项变化，会触发这个函数 因为通过v-model将选中项以数组的形式和goods_cat绑定了 但是最后上传给服务器的时候要字符串形式 但是又不能将其直接改为字符串（正向渲染不出来）所以先深拷贝一份总参数对象 装lodash包 里面有cloneDeep方法（参考对应官网）装好依赖后先在本组件里导入（推荐用_接收 类似jquery用$接收）然后调用cloneDeep方法拷贝一份 在这份新的里面转换好格式请求给服务器
    // handleChange() {
    //   console.log(this.addForm.goods_cat)
    //   if (this.addForm.goods_cat.length !== 3) { /* 没有选中三级项 清空 */
    //     this.addForm.goods_cat = []
    //   }
    // },
    // before-leave是标签页切换时会触发函数 函数会自动传入两个参数
    beforeTabLeave(activeName, oldActiveName) {
      console.log('即将离开的标签页名字是：' + oldActiveName)
      console.log('即将进入的标签页名字是：' + activeName)
      // return false 则不能切换 接下来要根据条件进行阻止
      // if (oldActiveName === '0' && this.addForm.goods_cat.length !== 3) {
      //   this.$message.error('请先选择商品分类！')
      //   return false
      // }
    },
    // 点击了标签
    async tabClicked() {
      // console.log(this.activeIndex)
      if (this.activeIndex === '1') { /* 证明访问的是动态参数面板 获取数据存储到manyTableData */
        const { data: res } = await this.$http.get(
          `categories/${this.cateId}/attributes`,
          {
            params: { sel: 'many' }
          }
        )

        if (res.code != 1) {
          return this.$message.error('获取动态参数列表失败！')
        }

        console.log(res.data)
        // 存储前先将attr_vals变为数组 供复选框组check-box-group渲染使用（这个是存储在了数据里面 而不是参数对象里面 参数对象里面还要另外加一个attrs存放这个 把数组转为字符串存到里面再发送出去）最后传给服务器时还要改回字符串
        res.data.forEach(item => {
          item.attr_vals =
            item.attr_vals.length === 0 ? [] : item.attr_vals.split(' ')
        })
        this.manyTableData = res.data
      } else if (this.activeIndex === '2') {
        const { data: res } = await this.$http.get(
          `categories/${this.cateId}/attributes`,
          {
            params: { sel: 'only' }
          }
        )
        if (res.code != 1) {
          return this.$message.error('获取静态属性失败！')
        }
        console.log(res.data)
        this.onlyTableData = res.data
      }
    },
    // 处理图片预览效果 on-preview是点击图片的名称触发这个事件
    handlePreview(file) {
      console.log(file) /* 当前图片的信息 有response-> data-> temp_path url url是图片路径可在网站上查看 所以可以在展示的对话框里将url与图片的src绑定 展示出来 */
      this.previewPath = file.response.data.url
      this.previewVisible = true
    },
    // 处理移除图片的操作 on-remove点击叉号触发
    handleRemove(file) {
      // console.log(file) 移除的图片的信息 依然有response-> data-> temp_path
      // 1. 获取将要删除的图片的临时路径
      const filePath = file.response.data.tmp_path
      // 2. 从 pics 数组中，找到这个图片对应的索引值 x是数组pics里的每一项 匹配上了findIndex就把索引值返回
      const i = this.addForm.pics.findIndex(x => x.pic === filePath)
      // 3. 调用数组的 splice 方法，把图片信息对象，从 pics 数组中移除
      this.addForm.pics.splice(i, 1)
      console.log(this.addForm) /* addForm即要发送请求的参数列表 */
    },
    // 监听图片上传成功 保存文件路径
    handleSuccess(response) {
      console.log(response) /* 就是network里显示的信息 */
      // 1. 拼接得到一个图片信息对象
      const picInfo = { pic: response.data.tmp_path }
      // 2. 将图片信息对象存储到pics数组中
      this.addForm.pics.push(picInfo)
      console.log(this.addForm) /* addForm即要发送请求的参数列表 */
    },
    // 添加商品 发送请求
    add() {
      // 先进行预验证
      this.$refs.addFormRef.validate(async valid => {
        if (!valid) {
          return this.$message.error('请填写必要的表单项！')
        }
        // 发起请求添加商品
        // 商品的名称，必须是唯一的   
        const { data: res } = await this.$http.post( this.id?'/Commodity/amendCommodity':'/Commodity/addCommodity', this.addForm)
        if (res.code != 1) {
          return this.$message.error(res.msg)
        }
        this.$message.success(res.data);
        this.$router.back();
      })
    }
  },
  computed: {
    // 选中的三级商品分类的id
    cateId() {
      if (this.addForm.goods_cat.length === 3) {
        return this.addForm.goods_cat[2]
      }
      return null
    }
  }
}
</script>

<style lang="less" scoped>
.el-checkbox {
  margin: 0 10px 0 0 !important;
}

.previewImg {
  width: 100%;
}

.btnAdd {
  margin-top: 15px;
}
</style>
